import React from "react";
// import products from "../products";
import { Link } from "react-router-dom";
import HomeItem from "../components/HomeItem";
import { useCart } from "react-use-cart";
import { FaCartPlus, FaCheck, FaExchangeAlt, FaPhoneVolume, FaShippingFast, FaEye, FaAngleDoubleUp } from "react-icons/fa";
import puma from "../puma";
import { Carousel } from 'antd';

function Home({ products }) {
    const {
        addItem,
    } = useCart();
    const addToCart = () => {
        addItem(puma[1])
    }

    return (
        <div>
            {/* <!-- Navbar Start --> */}
            
            <div className="container-fluid mb-5">
                <div className="row border-top px-xl-5">
                    <div className="col-lg-12">
                    {/* <div id="header-carousel" class="carousel slide" data-ride="carousel"> */}
                        <Carousel autoplay style={{height: '410px'}} >
                        <div className="carousel-item">
                            <img className="img-fluid" style={{height: '410px'}} width="1225px" src="https://neilpatel.com/wp-content/uploads/2018/05/ecommerce-product-images.jpg" alt=""  />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h4 className="text-light text-uppercase font-weight-medium mb-3">10% Off Your First Order</h4>
                                    <h3 className="display-4 text-white font-weight-semi-bold mb-4">All Products</h3>
                                    <Link to="/shop" className="btn btn-light py-2 px-3">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="img-fluid" style={{height: '410px'}} width="1225px" src="https://sportal365images.com/process/smp-images-production/ringier.africa/27032023/b37bb0ab-98ed-44f4-b854-9205c80472d5.png?" alt="" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h4 className="text-light text-uppercase font-weight-medium mb-3">10% Off Your First Order</h4>
                                    <h3 className="display-4 text-white font-weight-semi-bold mb-4">Puma X Davido</h3>
                                    <Link to="/collection/puma x davido" className="btn btn-light py-2 px-3">View Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img alt="" style={{height: '410px'}} width="1225px" src="https://images.unsplash.com/photo-1490114538077-0a7f8cb49891" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h4 className="text-light text-uppercase font-weight-medium mb-3">10% Off Your First Order</h4>
                                    <h3 className="display-4 text-white font-weight-semi-bold mb-4">Men Dresses</h3>
                                    <Link to="/category/11" className="btn btn-light py-2 px-3">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img style={{height: '410px'}} width="1225px" src="https://images.unsplash.com/photo-1506152983158-b4a74a01c721" alt="" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h4 className="text-light text-uppercase font-weight-medium mb-3">10% Off Your First Order</h4>
                                    <h3 className="display-4 text-white font-weight-semi-bold mb-4">Women Dresses</h3>
                                    <Link to="/category/14" className="btn btn-light py-2 px-3">Shop Now</Link>
                                </div>
                            </div>
                        </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}


            {/* <!-- Featured Start --> */}
            <div className="container-fluid pt-5">
                <div className="row px-xl-5 pb-3">
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
                            <h1 className="fa fa-check text-primary m-0 mr-3"><FaCheck /></h1>
                            <h5 className="font-weight-semi-bold m-0">Quality Product</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
                            <h1 className="fa fa-shipping-fast text-primary m-0 mr-2"><FaShippingFast /></h1>
                            <h5 className="font-weight-semi-bold m-0">Free Shipping</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
                            <h1 className="fas fa-exchange-alt text-primary m-0 mr-3"><FaExchangeAlt /></h1>
                            <h5 className="font-weight-semi-bold m-0">14-Day Return</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={{padding: '30px'}}>
                            <h1 className="fa fa-phone-volume text-primary m-0 mr-3"><FaPhoneVolume /></h1>
                            <h5 className="font-weight-semi-bold m-0">24/7 Support</h5>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Featured End --> */}


            {/* <!-- Categories Start --> */}
            <div className="container-fluid pt-5">
                <div className="row px-xl-5 pb-3">
                    <div className="col-lg-4 col-md-6 pb-1">
                        <div className="cat-item d-flex flex-column border mb-4" style={{padding: '10px'}}>
                            <p className="text-right">8 Products</p>
                            <Link to="/collection/puma x davido" className="cat-img position-relative overflow-hidden mb-3">
                                <img className="img-fluid"  src="https://dailycapsule.com/wp-content/uploads/2023/04/PUMA-Davido-1-1024x683.jpg" alt="" />
                            </Link>
                            <h5 className="font-weight-semi-bold m-0">PUMA x Davido</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pb-1">
                        <div className="cat-item d-flex flex-column border mb-4" style={{padding: '10px'}}>
                            <p className="text-right">15 Products</p>
                            <Link to="/category/11" className="cat-img position-relative overflow-hidden mb-3">
                                <img className="img-fluid" src="https://images.unsplash.com/photo-1479064555552-3ef4979f8908?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bWVuJTIwZmFzaGlvbnxlbnwwfDB8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60" alt="" />
                            </Link>
                            <h5 className="font-weight-semi-bold m-0">Men's dresses</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pb-1">
                        <div className="cat-item d-flex flex-column border mb-4" style={{padding: '10px'}}>
                            <p className="text-right">15 Products</p>
                            <Link to="/category/14" className="cat-img position-relative overflow-hidden mb-3">
                                <img className="img-fluid" src="https://images.unsplash.com/photo-1503342217505-b0a15ec3261c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8d29tZW4lMjBmYXNoaW9ufGVufDB8MHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="" />
                            </Link>
                            <h5 className="font-weight-semi-bold m-0">Women's dresses</h5>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <!-- Categories End --> */}


            {/* <!-- Offer Start --> */}
            <div className="container-fluid offer pt-5">
                <div className="row px-xl-5">
                    <div className="col-md-6 pb-4">
                        <div className="position-relative bg-secondary text-center text-md-right text-white mb-2 py-5 px-5">
                            <img src="../img/offer-1.png" alt="" />
                            <div className="position-relative" style={{zIndex: 1}}>
                                <h5 className="text-uppercase text-primary mb-3">20% off the all order</h5>
                                <h1 className="mb-4 font-weight-semi-bold">Spring Collection</h1>
                                <Link to="/shop" className="btn btn-outline-primary py-md-2 px-md-3">Shop Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 pb-4">
                        <div className="position-relative bg-secondary text-center text-md-left text-white mb-2 py-5 px-5">
                            <img src="../img/offer-2.png" alt="" />
                            <div className="position-relative" style={{zIndex: 1}}>
                                <h5 className="text-uppercase text-primary mb-3">20% off the all order</h5>
                                <h1 className="mb-4 font-weight-semi-bold">Winter Collection</h1>
                                <Link to="/shop" className="btn btn-outline-primary py-md-2 px-md-3">Shop Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Offer End --> */}


            {/* <!-- Products Start --> */}
            <div className="container-fluid pt-5">
                <div className="text-center mb-4">
                    <h2 className="section-title px-5"><span className="px-2">Trending Products</span></h2>
                </div>
                <div className="row px-xl-5 pb-3">
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="card product-item border-0 mb-4">
                            <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                <img className="img-fluid w-100" src={puma[1].thumbnail} alt={puma[1].title + '  image'} />
                            </div>
                            <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                <h6 className="text-truncate mb-3">{puma[1].title}</h6>
                                <div className="d-flex justify-content-center">
                                    <h6>${(puma[1].price - (puma[1].discountPercentage / 100) * puma[1].price)}</h6><h6 className="text-muted ml-2"><del>${puma[1].price}</del></h6>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-between bg-light border">
                                <Link to={`/puma x davido/102`} className="btn btn-sm text-dark p-0"><i style={{fontSize: "18px"}} className="fas fa-eye text-primary mr-1"><FaEye /></i> View Detail</Link>
                                <button onClick={addToCart} className="btn btn-sm text-dark p-0">Add To Cart <i style={{fontSize: "18px"}} className="fas fa-shopping-cart text-primary mr-1"> <FaCartPlus /></i></button>
                            </div>
                        </div>
                    </div>
                      {
                        products.slice(0, 7).map((item) => {
                          
                          return(
                                <HomeItem item={item} />
                              )
                          })
                      }
                </div>
            </div>
            {/* <!-- Products End --> */}


            {/* <!-- Subscribe Start --> */}
            <div className="container-fluid bg-secondary my-5">
                <div className="row justify-content-md-center py-5 px-xl-5">
                    <div className="col-md-6 col-12 py-5">
                        <div className="text-center mb-2 pb-2">
                            <h2 className="section-title px-5 mb-3"><span className="bg-secondary px-2">Stay Updated</span></h2>
                            <p>Amet lorem at rebum amet dolores. Elitr lorem dolor sed amet diam labore at justo ipsum eirmod duo labore labore.</p>
                        </div>
                        <form action="">
                            <div className="input-group">
                                <input type="text" className="form-control border-white p-4" placeholder="Email Goes Here" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary px-4">Subscribe</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!-- Subscribe End --> */}


            {/* <!-- Products Start --> */}
            <div className="container-fluid pt-5">
                <div className="text-center mb-4">
                    <h2 className="section-title px-5"><span className="px-2">Just Arrived</span></h2>
                </div>
                <div className="row px-xl-5 pb-3">
                <div className="container">
                    <div className="row">
                      {
                        products.slice(44, 52).map((item, id) => {
                          const discount = item.price - (item.discountPercentage / 100) * item.price
                          return(
                            <div key={id} className="col-md-3 col-xs-6">
                                <div className="product">
                                    <div className="product-img">
                                        <img src={item.thumbnail} alt={item.title} />
                                        <div className="product-label">
                                            <span className="sale">-{item.discountPercentage}%</span>
                                        </div>
                                    </div>
                                    <div className="product-body">
                                        <p className="product-category">{item.category}</p>
                                        <h3 className="product-name">{item.title}</h3>
                                        <h4 className="product-price">${discount.toFixed(2)} <del className="product-old-price">${item.price}</del></h4>
                                        <div className="product-rating">
                                        </div>
                                        <div className="product-btns">
                                            <button className="quick-view"><Link to={`/details/${item.id}`} ><FaEye className="text-primary"/><span className="tooltipp">quick view</span></Link> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          )
                        })
                    }
                    </div>
                </div>
                </div>
            </div>
            {/* <!-- Products End --> */}


            <Link to="/#" className="btn btn-primary back-to-top"><FaAngleDoubleUp /> </Link>
        </div>
    )
}
    
    



export default Home;
