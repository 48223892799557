import React, {useState, useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import categories from '../categories'
import { useCart } from "react-use-cart";
import { FaEye, FaShoppingCart, FaSearch  } from "react-icons/fa";
function Category({ products }) {
    const { id } = useParams();
    const findCategory = products?.filter((prod) => prod.category.toLowerCase().includes(id.toLowerCase()));
    console.log(findCategory, products)
    const productt = findCategory;
    
    const [search , setSearch] = useState('')
    const [filtered, setFiltered] = useState(productt)

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        const mySearch = productt.filter((product) => 
            product.title.toLowerCase().includes(search.toLowerCase())
        );
        setFiltered(mySearch)
    }, [search , productt])

    const {
        addItem,
        // inCart,
    } = useCart();
    const navigate = useNavigate();
    
    return (
        
        <div>
            {/* <!-- Page Header Start --> */}
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '300px'}}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">Product Category</h1>
                    <div className="d-inline-flex">
                        <p className="m-0"><Link to="">Home</Link></p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">Category/{id}</p>
                    </div>
                </div>
            </div>
            {/* <!-- Page Header End --> */}


            {/* <!-- Shop Start --> */}
            <div className="container-fluid pt-5">
                <div className="row px-xl-5">
                    {/* <!-- Shop Product Start --> */}
                    <div className="col-lg-12 col-md-12">
                        <div className="row pb-3">
                            <div className="col-12 pb-1">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <form action="">
                                        <div className="input-group">
                                            <input type="text" className="form-control" onChange={handleSearch} placeholder="Search by name" />
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-transparent text-primary">
                                                    <FaSearch />
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="dropdown ml-4">
                                        <button className="btn border dropdown-toggle" type="button" id="triggerId" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                    Sort by
                                                </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="triggerId">
                                            <Link className="dropdown-item" to="#">Latest</Link>
                                            <Link className="dropdown-item" to="#">Popularity</Link>
                                            <Link className="dropdown-item" to="#">Best Rating</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                {/* <!-- row --> */}
                                <div className="row">
                                    {/* <!-- product --> */}
                                    {
                                        filtered.map((item, id) => {
                                        const discount = item.price - (item.discountPercentage / 100) * item.price
                                        const addToCart = () => {
                                            addItem( item )
                                        }
                                        const linkTo = (e) => {
                                            e.preventDefault();
                                            navigate(`/details/${item.id}/#`)
                                        }
                                            return(
                                                <div key={id} className="col-md-3 col-xs-6">
                                                    <div className="product">
                                                        <div className="product-img">
                                                            <img src={item.thumbnail} alt="" />
                                                            <div className="product-label">
                                                                {/* <span className="sale"></span> */}
                                                            </div>
                                                        </div>
                                                        <div className="product-body">
                                                            <p className="product-category">{item.category}</p>
                                                            <h3 className="product-name">{item.title}</h3>
                                                            <h4 className="product-price">${discount.toFixed(2)} <del className="product-old-price">${item.price}.00</del></h4>
                                                            <div className="product-rating">
                                                            </div>
                                                            <div className="product-btns">
                                                                <button onClick={linkTo} className="quick-view"><FaEye/><span className="tooltipp">quick view</span></button>
                                                                <button onClick={addToCart} className="add-to-wishlist"><FaShoppingCart /><span className="tooltipp">add to cart</span></button>
                                                                {/* <button className="add-to-compare"><i className="fa fa-exchange"></i><span className="tooltipp">add to compare</span></button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    )
                                })
                            }
                            {/* <!-- /product --> */}
                        </div>
                        {/* <!-- /row --> */}
                    </div>
                        </div>
                    </div>
                    {/* <!-- Shop Product End --> */}
                </div>
            </div>
            {/* <!-- Shop End --> */}


        </div>
    )
}

export default Category;
