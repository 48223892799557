import React, { useState } from "react";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useCart } from "react-use-cart";

function Navbarr({gCategory}) {
    const { items } = useCart()
    const [searchInput, setSearchInput] = useState("");
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault(); // Prevent form submission from refreshing the page
        if (searchInput.trim()) {
            navigate(`/search?q=${searchInput.trim()}`);
            setSearchInput("");
        }
    };

    return (
        <div id="top">
            {/* <!-- Topbar Start --> */}
            <div id="top" className="container-fluid">
                <div className="row align-items-center py-3 px-xl-5">
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="text-decoration-none">
                            <h1 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">E</span>Shop</h1>
                        </Link>
                    </div>
                    <div className="col-lg-6 col-6 text-left">
                    <form onSubmit={handleSearch}>
                        <div className="input-group">
                            <input
                                type="search"
                                className="form-control"
                                placeholder="Search for products"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <div className="input-group-append">
                                <button type="submit" className="input-group-text bg-transparent text-primary">
                                    <FaSearch />
                                </button>
                            </div>
                        </div>
                    </form>
                    </div>
                    <div className="col-lg-3 col-6 text-right">
                        <Link to="/cart" className="btn border">
                            <FaShoppingCart className="fas text-primary" />
                            <span className="badge">{items.length}</span>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <!-- Topbar End --> */}


            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top px-xl-5">
                    <div className="col-lg-12">
                        <Navbar bg="light" expand="lg" className='py-3 py-lg-0 px-0'>
                                <Navbar.Brand href="#home"><h1 className="m-0 display-5 text-decoration-none d-block d-lg-none font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">E</span>Shop</h1></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Link className="nav-link" to="/">Home</Link>
                                    <Link className="nav-link" to="/shop">Shop</Link>
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                    <NavDropdown title="Collections" id="basic-nav-dropdown">
                                        <Link className="dropdown-item" to="/collection/puma x davido">Puma X Davido</Link>
                                    </NavDropdown>
                                    <Link className="nav-link" to="/category/11">Men</Link>
                                    <Link className="nav-link" to="/category/14">Women</Link>
                                    <NavDropdown title="Categories" id="basic-nav-dropdown">
                                    {/* <NavDropdown.Item> */}
                                    {
                                        gCategory.map(({group, categories}) => (
                                            <NavDropdown key={group} title={group} id="basic-nav-dropdown">
                                                {categories.map((category, i) => (
                                                    <Link className="dropdown-item" to={`/category/${category}`}>{category}</Link>
                                                ))}
                                            </NavDropdown>
                                        ))
                                    }
                                    {/* </NavDropdown.Item> */}
                                    </NavDropdown>
                                </Nav>
                                </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>
        </div>
    )
}
    
    



export default Navbarr;