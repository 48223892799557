import { Link, useLocation } from "react-router-dom";
import HomeItem from "../components/HomeItem";

const Search = ({ products }) => {
  const location = useLocation();

  // Extract the search query from the URL
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("q") || "";

  // Filter products based on the search query
  const filteredProducts = products?.filter((product) =>
    product.title?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
    product.category?.toLowerCase() === searchQuery?.toLowerCase() ||
    product.brand?.toLowerCase() === searchQuery?.toLowerCase() ||
    product.tags.some((tag) => tag?.toLowerCase().includes(searchQuery?.toLowerCase()))
  );

  console.log(filteredProducts);

  return (
    <>
      {/* Page Header Start */}
      <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "300px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">Search Results</h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <Link to="/">Home</Link>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Search</p>
          </div>
        </div>
      </div>
      {/* Page Header End */}

      {/* Display Search Results */}
      {searchQuery ? (
        <>
          {/* Products Start */}
          <div className="container-fluid pt-5">
            <div className="row px-xl-5 pb-3">
              {filteredProducts.length > 0 ? (
                filteredProducts.map((item) => (
                  <HomeItem key={item.id} item={item} />
                ))
              ) : (
                <h3 className="text-center w-100">No products found for "{searchQuery}".</h3>
              )}
            </div>
          </div>
          {/* Products End */}
        </>
      ) : (
        <h3 className="text-center mt-5">No search query provided. Try searching for something!</h3>
      )}
    </>
  );
};

export default Search;
