import { useEffect, useState } from "react";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom"
import Shop from "./pages/Shop";
import Navbarr from "./components/Navbar";
import Cart from "./pages/Cart";
import Detail from "./pages/Detail";
import Category from "./pages/Category";
import Checkout from "./pages/Checkout";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Davido from "./pages/Puma x Davido";
import DavidoDetails from "./pages/Puma x Davido Details";
import ScrollToTop from "./components/ScrollToTop";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './css/style.css';
import './css/styles.css';
import './css/pagination.css'
import Search from "./pages/Search";


function App(params) {
  const [product, setProduct] = useState([])

  useEffect(() => {
    fetchProduct()
  }, []);

  const fetchProduct = async () => {
    try {
      const res = await fetch('https://dummyjson.com/products?limit=100');
      const data = await res.json();
      setProduct(scatterProducts(data.products));
    } catch (error) {
      console.error(error);
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      // Random index from 0 to i
      const j = Math.floor(Math.random() * (i + 1));
  
      // Swap elements
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };  

  const scatterProducts = (array) => {
    const length = array.length;
    const newArray = [...array];  // Create a copy of the array to avoid mutating the original
    
    // Iterate over the array to perform random swaps
    for (let i = 0; i < length; i++) {
      // Random index from the array
      const randomIndex = Math.floor(Math.random() * length);
      
      // Swap the current element with the element at randomIndex
      [newArray[i], newArray[randomIndex]] = [newArray[randomIndex], newArray[i]];
    }
  
    return newArray;
  };
  
  const categories = [];
  for (const prod of product) {
    if (!categories.includes(prod.category)) {
      categories.push(prod.category);
    }
  }

  
  // Define the groups
  const groups = {
    "Fashion & Accessories": ["mens-shoes", "mens-shirts", "mens-watches", "tops"],
    "Home & Living": ["furniture", "home-decoration", "kitchen-accessories"],
    "Laptops & Accessories": ["laptops", "mobile-accessories", "smartphones"],
    "Essentials": ["groceries", "beauty", "fragrances", "skincare", "sunglasses"],
  };
  
  // Function to group categories
  const groupedCategories = Object.entries(groups).map(([groupName, items]) => ({
    group: groupName,
    categories: categories.filter(category => items.includes(category)),
  }));
  
  console.log(groupedCategories);
  

  return(
    <div>
      <ScrollToTop />
        <Navbarr gCategory={groupedCategories} />
        <Routes>
          <Route path="/" element={<Home products={product} />} />
          <Route path="/shop" element={<Shop products={product} />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Details/:id" element={<Detail  products={product} />} />
          <Route path="/Category/:id" element={<Category products={product} />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/collection/puma x davido" element={<Davido  />} />
          <Route path="/puma x Davido/:id" element={<DavidoDetails />} />
          <Route path="/search" element={<Search products={product} />} />
        </Routes>
        <Footer />
    </div>
  )
}

export default App;